<template>
    <div
        v-if="items.length"
        ref="imageGallery"
        v-resize="calcThumbSize"
        class="gst-image-gallery-modal d-flex flex-column align-center justify-center justify-md-center u-height-100">
        <v-btn
            fab
            text
            x-small
            class="gst-image-gallery-modal-close text-capitalize gst-btn--cancel px-0"
            @click="$emit('close')">
            <IconClose />
        </v-btn>
        <div
            class="u-position-relative u-width-100 u-height-100 mt-lg-4 mb-lg-0"
            :style="{ 'max-width': carouselMaxWidth, 'max-height': carouselMaxHeight }">
            <v-carousel
                v-model="activeItem"
                class="gst-image-gallery-modal__carousel rounded transparent"
                :hide-delimiters="true"
                :continuous="false"
                :show-arrows="false"
                :height="carouselHeight">
                <v-carousel-item
                    v-for="(item, index) in itemsWithoutError"
                    :key="index"
                    class="gst-image-gallery-modal__carousel-item"
                    :class="{
                        'gst-image-gallery-modal__carousel-item--error-image': errorLoadImages && errorLoadImages.includes(index)
                    }">
                    <BaseImage
                        v-if="errorLoadImages && errorLoadImages.includes(item)"
                        :src="null"
                        width="100%"
                        height="100%" />
                    <BaseImage
                        v-else
                        :src="item.fallbackSrc"
                        :fallback-src="item.src"
                        width="100%"
                        height="100%"
                        has-placeholder />
                </v-carousel-item>
            </v-carousel>
            <div class="gst-image-gallery-modal__carousel-navigation">
                <BaseButton
                    class="gst-image-gallery-modal__carousel-navigation-prev"
                    :disabled="isCarouselOnStart"
                    fab
                    small
                    text
                    @click="onClickPrevDo">
                    <ChevronDownIcon class="u-rotate-90" />
                </BaseButton>
                <BaseButton
                    class="gst-image-gallery-modal__carousel-navigation-next"
                    :disabled="isCarouselOnEnd"
                    fab
                    small
                    text
                    @click="onClickNextDo">
                    <ChevronDownIcon class="u-rotate-90-n" />
                </BaseButton>
            </div>
        </div>
        <div class="gst-image-gallery-modal__pagination-index my-8">
            {{ paginationIndex }}
        </div>
        <v-slide-group
            ref="slideGroup"
            v-model="activeItem"
            :style="thumbListStyle"
            class="gst-image-gallery-modal__thumbs"
            center-active
            mandatory
            :show-arrows="false">
            <v-slide-item
                v-for="(item, index) in itemsWithoutError"
                :key="index"
                v-slot="{ active, toggle }"
                class="gst-image-gallery-modal__thumbs-item">
                <v-card
                    :style="thumbItemStyle"
                    class="rounded transparent overflow-hidden"
                    :class="{
                        'gst-image-gallery-modal__thumbs-item-active': active,
                        'gst-image-gallery-modal__thumbs-item-error-image': errorLoadImages && errorLoadImages.includes(item)
                    }"
                    elevation="0"
                    @click="toggle">
                    <BaseImage
                        :src="item.src"
                        :fallback-src="item.fallbackSrc"
                        :width="thumbWidth"
                        height="100%"
                        @error-load="onImageErrorLoadDo( item )" />
                </v-card>
            </v-slide-item>
        </v-slide-group>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import ChevronDownIcon from '@core/shared/assets/icons/chevron_down.svg';
    import IconClose from '@core/shared/assets/icons/close.svg';
    import { getUnique as getUniqueArray } from '@core/utils/arrayUtils';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import { getImageByWidth } from '@tenants/ticketmaster/app/utils/imageModelUtils';
    import { IMAGE_SIZE } from '@tenants/ticketmaster/app/utils/constants/imageData';

    export default {
        name: 'ImageGalleryModal',
        components: {
            ChevronDownIcon,
            BaseButton,
            IconClose,
            BaseImage
        },
        mixins: [ CloseModalOnRouteChangeMixin ],
        props: {
            items: {
                type: Array,
                default: () => []
            },
            carouselMaxWidth: {
                type: String,
                default: '65vw'
            },
            carouselMaxHeight: {
                type: String,
                default: '70vh'
            },
            carouselHeightDesktop: {
                type: String,
                default: '100%'
            },
            carouselHeightMobile: {
                type: String,
                default: '280px'
            },
            thumbsToShow: {
                type: Object,
                default: () => ( { xs: 3.5, sm: 9, md: 9, lg: 9, xl: 9 } )
            },
            thumbGap: {
                type: String,
                default: '4px'
            },
            initialActiveItem: {
                type: Number,
                default: 0
            }
        },
        data( ) {
            return {
                activeItem: 0,
                thumbWidth: 0,
                thumbHeight: 0,
                errorLoadImages: []
            };
        },
        computed: {
            itemsWithoutError( ) {
                return this.items
                    .map( image => {
                        return {
                            src: getImageByWidth( IMAGE_SIZE.LARGE, image ),
                            fallbackSrc: getImageByWidth( IMAGE_SIZE.ORIGINAL, image )
                        };
                    } )
                    .filter( item => this.errorLoadImages.indexOf( item.src ) === -1 );
            },
            paginationIndex( ) {
                return this.$t( '_common:terms.currentOfTotal', { current: this.activeItem + 1, total: this.itemsWithoutError.length } );
            },
            isCarouselOnStart( ) {
                return this.activeItem === 0;
            },
            isCarouselOnEnd( ) {
                return this.activeItem === ( this.itemsWithoutError.length - 1 );
            },
            carouselHeight( ) {
                return this.$vuetify.breakpoint.smAndUp ? this.carouselHeightDesktop : this.carouselHeightMobile;
            },
            thumbListStyle( ) {
                return { marginLeft: `-${this.thumbGap}`, marginRight: `-${this.thumbGap}` };
            },
            thumbItemStyle( ) {
                return { marginLeft: this.thumbGap, marginRight: this.thumbGap };
            }
        },
        methods: {
            onClickPrevDo( ) {
                this.isCarouselOnStart || ( this.activeItem = this.activeItem - 1 );
            },
            onClickNextDo( ) {
                this.isCarouselOnEnd || ( this.activeItem = this.activeItem + 1 );
            },
            calcThumbSize: debounce( function( ) {
                if ( this.$refs.slideGroup ) {
                    const slideGroup = this.$refs.slideGroup.$el.getBoundingClientRect( );
                    const thumbsToShow = this.thumbsToShow[this.$vuetify.breakpoint.name];
                    const thumbWidth = ( slideGroup.width / thumbsToShow ) - ( 2 * parseInt( this.thumbGap ) );

                    this.thumbWidth = thumbWidth.toFixed( 2 );
                }
            }, 250 ),
            onImageErrorLoadDo( item ) {
                this.errorLoadImages = getUniqueArray( [
                    ...this.errorLoadImages,
                    item.src
                ] );
            }
        },
        mounted( ) {
            this.activeItem = this.initialActiveItem;
            this.$nextTick( () => this.calcThumbSize( ) );
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .v-dialog--fullscreen {
        background-color: theme-color( 'black' ) !important;
        opacity: 1 !important;
    }

    .gst-v-dialog-gallery {
        background-color: rgba( 0, 0, 0, 0.5 ) !important;
    }

    .gst-image-gallery-modal {
        background-color: rgba( 0, 0, 0, 0.8 ) !important;

        .gst-image-gallery-modal-close {
            position: fixed;
            top: 12px;
            right: 12px;

            .gst-svg-icon {
                fill: theme-color( 'white' );
            }
        }

        .gst-image-gallery-modal__carousel {
            .gst-image-gallery-modal__carousel-item--error-image {
                background: theme-color( 'white' );
            }
        }

        .gst-image-gallery-modal__carousel-navigation {
            .gst-image-gallery-modal__carousel-navigation-prev,
            .gst-image-gallery-modal__carousel-navigation-next {
                position: absolute;
                top: 50%;
                background-color: theme-color( 'white' );
                transform: translateY( -50% );

                svg {
                    .gst-svg-icon {
                        fill: theme-color( 'primary' );
                    }
                }
            }

            .gst-image-gallery-modal__carousel-navigation-prev {
                left: -60px;
            }

            .gst-image-gallery-modal__carousel-navigation-next {
                right: -60px;
            }

            .gst-image-gallery-modal__carousel-navigation-prev.v-btn--disabled,
            .gst-image-gallery-modal__carousel-navigation-next.v-btn--disabled {
                opacity: 0.4;
            }
        }

        .gst-image-gallery-modal__pagination-index {
            line-height: line-height( 'xl' );
            color: theme-color( 'tertiary' );
            font-size: font-size( 's' );
        }

        .gst-image-gallery-modal__thumbs {
            height: 10vh !important;
            width: 85% !important;

            .gst-image-gallery-modal__thumbs-item-active::after {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border: 2px solid theme-color( 'primary' ) !important;
                background: rgba( theme-color-hex( 'primary' ), 0.4 );
                content: "";
            }

            .gst-image-gallery-modal__thumbs-item-error-image {
                background: theme-color( 'white' ) !important;
            }

            ::v-deep .v-slide-group__prev,
            ::v-deep .v-slide-group__next {
                display: none;
            }
        }
    }

    @include mobile-only {
        .gst-image-gallery-modal {
            ::v-deep .gst-image-gallery-modal__carousel {
                .v-window-item {
                    display: flex;
                    align-items: center;
                }

                .v-carousel__item {
                    height: auto !important;
                }
            }

            .gst-image-gallery-modal__carousel-navigation {
                .gst-image-gallery-modal__carousel-navigation-prev,
                .gst-image-gallery-modal__carousel-navigation-next {
                    .gst-svg-icon {
                        fill: theme-color( 'white' );
                    }
                }

                .gst-image-gallery-modal__carousel-navigation-prev {
                    left: 0;
                }

                .gst-image-gallery-modal__carousel-navigation-next {
                    right: 0;
                }
            }
        }
    }
</style>
