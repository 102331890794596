var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.calcThumbSize),expression:"calcThumbSize"}],ref:"imageGallery",staticClass:"gst-image-gallery-modal d-flex flex-column align-center justify-center justify-md-center u-height-100"},[_c('v-btn',{staticClass:"gst-image-gallery-modal-close text-capitalize gst-btn--cancel px-0",attrs:{"fab":"","text":"","x-small":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('IconClose')],1),_c('div',{staticClass:"u-position-relative u-width-100 u-height-100 mt-lg-4 mb-lg-0",style:({ 'max-width': _vm.carouselMaxWidth, 'max-height': _vm.carouselMaxHeight })},[_c('v-carousel',{staticClass:"gst-image-gallery-modal__carousel rounded transparent",attrs:{"hide-delimiters":true,"continuous":false,"show-arrows":false,"height":_vm.carouselHeight},model:{value:(_vm.activeItem),callback:function ($$v) {_vm.activeItem=$$v},expression:"activeItem"}},_vm._l((_vm.itemsWithoutError),function(item,index){return _c('v-carousel-item',{key:index,staticClass:"gst-image-gallery-modal__carousel-item",class:{
                    'gst-image-gallery-modal__carousel-item--error-image': _vm.errorLoadImages && _vm.errorLoadImages.includes(index)
                }},[(_vm.errorLoadImages && _vm.errorLoadImages.includes(item))?_c('BaseImage',{attrs:{"src":null,"width":"100%","height":"100%"}}):_c('BaseImage',{attrs:{"src":item.fallbackSrc,"fallback-src":item.src,"width":"100%","height":"100%","has-placeholder":""}})],1)}),1),_c('div',{staticClass:"gst-image-gallery-modal__carousel-navigation"},[_c('BaseButton',{staticClass:"gst-image-gallery-modal__carousel-navigation-prev",attrs:{"disabled":_vm.isCarouselOnStart,"fab":"","small":"","text":""},on:{"click":_vm.onClickPrevDo}},[_c('ChevronDownIcon',{staticClass:"u-rotate-90"})],1),_c('BaseButton',{staticClass:"gst-image-gallery-modal__carousel-navigation-next",attrs:{"disabled":_vm.isCarouselOnEnd,"fab":"","small":"","text":""},on:{"click":_vm.onClickNextDo}},[_c('ChevronDownIcon',{staticClass:"u-rotate-90-n"})],1)],1)],1),_c('div',{staticClass:"gst-image-gallery-modal__pagination-index my-8"},[_vm._v(" "+_vm._s(_vm.paginationIndex)+" ")]),_c('v-slide-group',{ref:"slideGroup",staticClass:"gst-image-gallery-modal__thumbs",style:(_vm.thumbListStyle),attrs:{"center-active":"","mandatory":"","show-arrows":false},model:{value:(_vm.activeItem),callback:function ($$v) {_vm.activeItem=$$v},expression:"activeItem"}},_vm._l((_vm.itemsWithoutError),function(item,index){return _c('v-slide-item',{key:index,staticClass:"gst-image-gallery-modal__thumbs-item",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var active = ref.active;
                var toggle = ref.toggle;
return [_c('v-card',{staticClass:"rounded transparent overflow-hidden",class:{
                    'gst-image-gallery-modal__thumbs-item-active': active,
                    'gst-image-gallery-modal__thumbs-item-error-image': _vm.errorLoadImages && _vm.errorLoadImages.includes(item)
                },style:(_vm.thumbItemStyle),attrs:{"elevation":"0"},on:{"click":toggle}},[_c('BaseImage',{attrs:{"src":item.src,"fallback-src":item.fallbackSrc,"width":_vm.thumbWidth,"height":"100%"},on:{"error-load":function($event){return _vm.onImageErrorLoadDo( item )}}})],1)]}}],null,true)})}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }